import React,{useState, useEffect} from 'react';

import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import {alpha} from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// import {account} from 'src/_mock/account';
import {useRouter} from "../../../routes/hooks";
import storeUserServices from "../../../services/storeUserServices";
import AppSettingsServices from 'src/services/appSettingsServices';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Settings',
        icon: 'eva:settings-2-fill',
    },
];



// ----------------------------------------------------------------------

export default function AccountPopover() {
    const [open, setOpen] = useState(null);
    const router = useRouter()
    const [nameSurname,setNameSurname] = useState(null)
    const [logo, setLogo] = React.useState('https://yetisplus.com/images/brand_logo.png');
    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const logout = () => {
        localStorage.removeItem('_authToken')
        router.push('/login')
    };

    const settings = () => {
        router.push('/settings')
        setOpen(null);
    }
    React.useEffect(() => {
        AppSettingsServices.getAll().then((res) => {
          const appSettingData = res?.data?.data 
          setLogo(appSettingData[0]?.logo_url) 
          
        });
      },[])

    const token = localStorage.getItem('_authToken');

    storeUserServices.me(token).then((res) => {
        setNameSurname(`${res.data.data.name} ${res.data.data.surname}`)
    })

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    background: (theme) => alpha(theme.palette.grey[500], 0.08),
                    ...(open && {
                        background: (theme) =>
                            `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    }),
                }}
            >
                <Avatar
                    src={logo}
                    alt="logo"
                    sx={{
                        width: 36,
                        height: 36,
                        border: (theme) => `solid 2px ${theme.palette.background.default}`,
                    }}
                >
                    {/*{account.displayName.charAt(0).toUpperCase()}*/}
                </Avatar>
            </IconButton>

            <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1,
                        ml: 0.75,
                        width: 200,
                    },
                }}
            >
                <Box sx={{my: 1.5, px: 2}}>
                    {/*<Typography variant="subtitle2" noWrap>*/}
                    {/*    {account.displayName}*/}
                    {/*</Typography>*/}
                    <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                        {nameSurname}
                    </Typography>
                </Box>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <MenuItem  onClick={() => settings()}>
                    Settings
                </MenuItem>

                <Divider sx={{borderStyle: 'dashed', m: 0}}/>

                <MenuItem
                    disableRipple
                    disableTouchRipple
                    onClick={logout}
                    sx={{typography: 'body2', color: 'error.main', py: 1.5}}
                >
                    Logout
                </MenuItem>
            </Popover>
        </>
    );
}
