// src/routes.js
import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from 'src/layouts/dashboard';
import AuthMiddleware from '../middleware/authMiddleware';
import GuestMiddleware from '../middleware/guestMiddleware';

export const IndexPage = lazy(() => import('src/pages/Overview'));
export const Page404 = lazy(() => import('src/pages/page-not-found'));

export const Products = lazy(() => import('src/pages/products'));
export const LoginPage = lazy(() => import('src/pages/login'));
export const RegisterPage = lazy(() => import('src/pages/Register'));

// Orders Pages
export const Orders = lazy(() => import('src/pages/orders/Orders.page'));
export const OrdersDetail = lazy(() => import('src/pages/orders/OrdersDetail.page'));
export const OrdersCanceled = lazy(() => import('src/pages/orders/CanceledOrders.page'))

export const UpdateNote = lazy(() => import('src/pages/UpdateNote'));
export const ServicePoints = lazy(() => import('src/pages/ServicePoints'));

export const DetailProduct = lazy(() => import('src/pages/detail-product'));
export const Settings = lazy(() => import('src/pages/Settings'));
export const PaymentInformationPage = lazy(() => import('src/pages/paymentInformation'));
export const ForgotPassword = lazy(() => import('src/pages/forgot-password'));
export const StoreDocs= lazy(() => import('src/pages/StoreDocs'));
export const StoreStream = lazy(() => import('src/pages/StoreStream'));

// USERS PAGES
export const Users = lazy(() => import('src/pages/Users.page'));
export const UsersCreate = lazy(() => import('src/pages/UsersCreate.page'));
export const UsersDetails = lazy(() => import('src/pages/UserDetail.page'));

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        {
          element: (
            <AuthMiddleware>
              <IndexPage />
            </AuthMiddleware>
          ),
          index: true,
        },
        // USERS ROUTES
        {
          path: 'users',
          element: (
            <AuthMiddleware>
              <Users />
            </AuthMiddleware>
          ),
        },
        {
          path: 'users/create',
          element: (
            <AuthMiddleware>
              <UsersCreate />
            </AuthMiddleware>
          ),
        },
        {
          path: 'user/:id',
          element: (
            <AuthMiddleware>
              <UsersDetails />
            </AuthMiddleware>
          ),
        },
        {
          path: 'products',
          element: (
            <AuthMiddleware>
              <Products />
            </AuthMiddleware>
          ),
        },
        {
          path: 'product/:id',
          element: (
            <AuthMiddleware>
              <DetailProduct />
            </AuthMiddleware>
          ),
        },
        {
          path: 'stats',
          element: (
            <AuthMiddleware>
              <IndexPage />
            </AuthMiddleware>
          ),
        },
        // Orders Routes
        {
          path: 'orders',
          element: (
            <AuthMiddleware>
              <Orders />
            </AuthMiddleware>
          ),
        },
        {
          path: 'orders/:id',
          element: (
            <AuthMiddleware>
              <OrdersDetail />
            </AuthMiddleware>
          ),
        },
        {
          path: 'orders/canceled',
          element: (
            <AuthMiddleware>
              <OrdersCanceled />
            </AuthMiddleware>
          ),
        },

        {
          path: 'updates',
          element: (
            <AuthMiddleware>
              <UpdateNote />
            </AuthMiddleware>
          ),
        },
        {
          path: 'servicePoints',
          element: (
            <AuthMiddleware>
              <ServicePoints />
            </AuthMiddleware>
          ),
        },
        {
          path: 'settings',
          element: (
            <AuthMiddleware>
              <Settings />
            </AuthMiddleware>
          ),
        },
        {
          path: 'payment-information',
          element: (
            <AuthMiddleware>
              <PaymentInformationPage />
            </AuthMiddleware>
          ),
        },
        {
          path: 'storeStream',
          element: (
            <AuthMiddleware>
              <StoreStream />
            </AuthMiddleware>
          ),
        },
        {
          path: 'storeDocs',
          element: (
            <AuthMiddleware>
              <StoreDocs />
            </AuthMiddleware>
          ),
        },
      ],
    },
    {
      path: 'login',
      element: (
        <GuestMiddleware>
          <LoginPage />
        </GuestMiddleware>
      ),
    },
    // {
    //     path: 'register',
    //     element: (
    //         <GuestMiddleware>
    //             <RegisterPage/>
    //         </GuestMiddleware>
    //     ),
    // },
    {
      path: 'forgot-password',
      element: (
          <GuestMiddleware>
              <ForgotPassword/>
          </GuestMiddleware>
      ),
  },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
